<template>
  <card class="card p-0 m-2 sm:m-3">
    <template #content>
      <Accordion v-model:activeIndex="active_index">
        <AccordionTab
          v-for="(ticket, index) in structure_ticket"
          :key="'ticket_' + index"
        >
          <template #header>
            <div class="ticket-title flex flex-wrap">
              Registro de {{ ticket.name }}
              <Badge
                :value="
                  checkValidTicket(ticket)
                    ? 'Registro Completo'
                    : 'Complete el Registro'
                "
                :severity="checkValidTicket(ticket) ? 'success' : 'warning'"
                class="ml-2"
              ></Badge>
            </div>
          </template>
          <card class="inner-card mb-3">
            <template #content>
              <button
                @click="autocomplete()"
                class="justify-content-end font-bold py-2 px-4 auto-fill mb-4"
                v-if="index === 0"
              >
                {{autofilled ? 'Limpiar Datos' : 'Autocompletar Datos'}}
              </button>
              <div class="formgrid grid" v-if="ticket.category !== 'commun'">
                <div class="field col-12 md:col-6 lg:col-6 mb-5">
                  <span class="p-float-label">
                    <InputText
                      v-model="ticket.enterprise_name"
                      class="w-full"
                      autocomplete="off"
                      maxlength="20"
                      @keypress="restrictCharts($event)"
                    />
                    <label class="input-form">Nombre de la Empresa *</label>
                  </span>
                  <small
                    v-if="!checkPersonData('enterprise_name', ticket, index)"
                    v-show="submitted"
                    class="p-error"
                    >Campo requerido.</small
                  >
                </div>
                <div class="field col-12 md:col-6 lg:col-6 mb-5">
                  <span class="p-float-label">
                    <InputText
                      v-model="ticket.product_name"
                      class="w-full"
                      autocomplete="off"
                      maxlength="20"
                      @keypress="restrictCharts($event)"
                    />
                    <label class="input-form">Proyecto *</label>
                  </span>
                  <small
                    v-if="!checkPersonData('product_name', ticket, index)"
                    v-show="submitted"
                    class="p-error"
                    >Campo requerido.</small
                  >
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field col-12 md:col-4 lg:col-4 mb-5">
                  <span class="p-float-label">
                    <InputText
                      v-model="ticket.person_data.dni"
                      class="w-full"
                      autocomplete="off"
                      maxlength="50"
                      :readonly="index === 0 && autofilled"
                      @change="verifyPersonDni()"
                    />
                    <label class="input-form">Documento de identidad *</label>
                  </span>
                  <small
                    v-if="!checkPersonData('dni', ticket, index)"
                    v-show="submitted"
                    class="p-error"
                    >Campo requerido.</small
                  >
                  <small
                    v-else-if="ticket.validate_dni === true"
                    class="p-error"
                  >
                    Esta cédula ya posee una entrada asignada
                  </small>
                </div>
                <div class="field col-12 md:col-4 lg:col-4 mb-5">
                  <div class="p-float-label">
                    <InputText
                      v-model="ticket.person_data.first_name"
                      class="w-full"
                      autocomplete="off"
                      maxlength="50"
                      :readonly="index === 0 && autofilled"
                      @keypress="restrictCharts($event)"
                    />
                    <label class="input-form">Nombre *</label>
                  </div>
                  <small
                    v-if="!checkPersonData('first_name', ticket, index)"
                    v-show="submitted"
                    class="p-error"
                    >Campo requerido.</small
                  >
                </div>
                <div class="field col-12 md:col-4 lg:col-4 mb-5">
                  <div class="p-float-label">
                    <InputText
                      v-model="ticket.person_data.last_name"
                      class="w-full"
                      autocomplete="off"
                      maxlength="50"
                      :readonly="index === 0 && autofilled"
                      @keypress="restrictCharts($event)"
                    />
                    <label class="input-form">Apellido *</label>
                  </div>
                  <small
                    v-if="!checkPersonData('last_name', ticket, index)"
                    v-show="submitted"
                    class="p-error"
                    >Campo requerido.</small
                  >
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-6 mb-5">
                  <span class="p-float-label">
                    <InputText
                      v-model="ticket.person_data.mail"
                      class="w-full"
                      autocomplete="off"
                      maxlength="50"
                      :readonly="index === 0 && autofilled && ticket.locked_email"
                    />
                    <label class="input-form">Correo Electrónico *</label>
                  </span>
                  <small
                    v-if="!checkPersonData('mail', ticket, index)"
                    v-show="submitted"
                    class="p-error"
                    >Ingrese un email válido.</small
                  >
                </div>
                <div class="field col-12 md:col-6 lg:col-6 mb-5">
                  <div style="position: relative">
                    <label class="input-phone-label input-form"
                      >Teléfono *</label
                    >
                    <InputText
                      v-if="index === 0 && autofilled"
                      class="w-full"
                      autocomplete="off"
                      maxlength="50"
                      :readonly="true"
                      :value="ticket.person_data.phone_number"
                    />
                    <MazPhoneNumberInput
                      v-else
                      v-model="ticket.person_data.phone_number"
                      show-code-on-list
                      color="info"
                      autocomplete="off"
                      :fetch-country="false"
                      default-country-code="VE"
                      @update="
                        checkPersonData('phone_number', ticket, index, $event)
                      "
                      :translations="phone_translations"
                    />
                  </div>
                  <small
                    v-if="!ticket.validations.phone_number"
                    v-show="submitted"
                    class="p-error"
                    >Ingrese un Teléfono válido.</small
                  >
                </div>
              </div>
              <div
                v-for="(value, index2) in ticket.ticket_structure"
                :key="value.id"
              >
                <card class="inner-card my-3">
                  <template #title>
                    <div class="flex flex-wrap">
                      Registro de {{ value.name }}
                      <Badge
                        :value="
                          checkValidTicket(value)
                            ? 'Registro Completo'
                            : 'Registro Incompleto'
                        "
                        :severity="
                          checkValidTicket(value) ? 'success' : 'warning'
                        "
                        class="ml-2"
                      ></Badge>
                    </div>
                    <hr />
                  </template>
                  <template #content>
                    <div class="info_note mb-4">
                      <ul>
                        <li>
                          Los datos para el siguiente formulario puede dejarlos
                          vacíos y podrá rellenarlos posteriormente si así lo
                          prefiere.
                        </li>
                      </ul>
                    </div>
                    <div class="formgrid grid">
                      <div class="field col-12 md:col-4 lg:col-4 mb-5">
                        <span class="p-float-label">
                          <InputText
                            v-model="value.dni"
                            class="w-full"
                            autocomplete="off"
                            maxlength="50"
                            @change="verifyPersonDni()"
                          />
                          <label class="input-form"
                            >Documento de identidad *</label
                          >
                        </span>
                        <small
                          v-if="!checkPersonData('dni', value, index2)"
                          v-show="submitted"
                          class="p-error"
                          >Campo requerido.</small
                        >
                        <small
                          v-else-if="value.validate_dni === true"
                          class="p-error"
                        >
                          Esta cédula ya posee una entrada asignada
                        </small>
                      </div>
                      <div class="field col-12 md:col-4 lg:col-4 mb-5">
                        <div class="p-float-label">
                          <InputText
                            v-model="value.first_name"
                            class="w-full"
                            autocomplete="off"
                            maxlength="50"
                            @keypress="restrictCharts($event)"
                          />
                          <label class="input-form">Nombre *</label>
                        </div>
                        <small
                          v-if="!checkPersonData('first_name', value, index2)"
                          v-show="submitted"
                          class="p-error"
                          >Campo requerido.</small
                        >
                      </div>
                      <div class="field col-12 md:col-4 lg:col-4 mb-5">
                        <div class="p-float-label">
                          <InputText
                            v-model="value.last_name"
                            class="w-full"
                            autocomplete="off"
                            maxlength="50"
                            @keypress="restrictCharts($event)"
                          />
                          <label class="input-form">Apellido *</label>
                        </div>
                        <small
                          v-if="!checkPersonData('last_name', value, index2)"
                          v-show="submitted"
                          class="p-error"
                          >Campo requerido.</small
                        >
                      </div>
                    </div>
                    <div class="formgrid grid">
                      <div class="field col-12 md:col-6 lg:col-6 mb-5">
                        <span class="p-float-label">
                          <InputText
                            v-model="value.mail"
                            class="w-full"
                            autocomplete="off"
                            maxlength="50"
                          />
                          <label class="input-form">Correo Electrónico *</label>
                        </span>
                        <small
                          v-if="!checkPersonData('mail', value, index2)"
                          v-show="submitted"
                          class="p-error"
                          >Ingrese un email válido.</small
                        >
                      </div>
                      <div class="field col-12 md:col-6 lg:col-6 mb-5">
                        <div style="position: relative">
                          <label class="input-phone-label input-form"
                            >Teléfono *</label
                          >
                          <MazPhoneNumberInput
                            v-model="value.phone_number"
                            show-code-on-list
                            color="info"
                            autocomplete="off"
                            :fetch-country="false"
                            default-country-code="VE"
                            @update="
                              checkPersonData(
                                'phone_number',
                                value,
                                index2,
                                $event
                              )
                            "
                            :translations="phone_translations"
                          />
                        </div>
                        <small
                          v-if="!value.validations.phone_number"
                          v-show="submitted"
                          class="p-error"
                          >Ingrese un Teléfono válido.</small
                        >
                      </div>
                    </div>
                  </template>
                </card>
              </div>
            </template>
          </card>
        </AccordionTab>
      </Accordion>
      <div class="grid flex justify-content-between mt-5">
        <div
          class="col-12 md:col-6 lg:col-6 flex justify-content-center md:justify-content-start"
        >
          <button class="continue-button" @click="activeForm">VOLVER</button>
        </div>
        <div
          class="col-12 md:col-6 lg:col-6 flex justify-content-center md:justify-content-end"
        >
          <button class="continue-button" @click="request()">REGISTRAR</button>
        </div>
      </div>
    </template>
  </card>
  <ConfirmDialog></ConfirmDialog>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

export default {
  components: {
    MazPhoneNumberInput,
    Accordion,
    AccordionTab
  },
  props: ["tickets_selected"],
  emits: ["activeForm", "backToSelect"],
  data() {
    return {
      autofilled: false,
      structure_ticket: [],
      ticket_selected_structure: [],
      active_client: "",
      dni_repeat: true,
      active_index: null,
      submitted: false,
      phone_translations: {
        countrySelector: {
          placeholder: "Código País",
          error: "Elija un país",
        },
        phoneInput: {
          placeholder: "Numéro de teléfono",
          example: "Ejemplo:",
        },
      },
    };
  },
  created() {
    for (let i = 0; i < this.tickets_selected.length; i++) {
      let ticket_selected = this.tickets_selected[i];
      ticket_selected.ticket_structure = [];
      let counter = 0;
      // Creating a new array of objects called ticket_structure.
      if (ticket_selected.structure) {
        ticket_selected.structure.forEach((element) => {
          for (let l = 0; l < element.count; l++) {
            ticket_selected.ticket_structure.push({
              name: element.name,
              ticket_id: element.ticket_id,
              id: counter++,
              validate_dni: false,
            });
          }
        });
      }
      for (let k = 0; k < ticket_selected.ticket_count; k++) {
        this.structure_ticket.push({
          id: k,
          fk_event: ticket_selected.fk_event,
          category: ticket_selected.category,
          structure: ticket_selected.structure,
          name: ticket_selected.name,
          ticket_id: ticket_selected.id,
          ticket_structure: ticket_selected.ticket_structure,
          person_data: {},
          validate_dni: false,
        });
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listening to a message event from the parent window.
    eventer(messageEvent, (e) => {
      let key = e.message ? "message" : "data";
      let data = e[key];

      // Setting the values of the structure_ticket object to the values of the active_client object.
      if (data.active_client) {
        this.active_client = data.active_client;
        let structure_selected = this.structure_ticket[0];
        structure_selected.person_data.phone_number =
          this.active_client.phone_number;
        structure_selected.person_data.mail = this.active_client.mail;
        structure_selected.person_data.last_name = this.active_client.last_name;
        structure_selected.person_data.first_name =
          this.active_client.first_name;
        structure_selected.person_data.dni = this.active_client.dni;
        this.verifyPersonDni();
        structure_selected.locked_email = this.checkPersonData("mail", structure_selected);
        structure_selected.locked_phone = this.checkPersonData("phone_number", structure_selected);
        this.autofilled = true;
      }
      // Checking if the data.status_product_consult is equal to 200. If it is, it will emit an event called
      // backToSelect
      if (data.status_product_consult) {
        if (data.status_product_consult === 200) {
          this.$emit("backToSelect");
        }
      }
    });
    this.active_index = 0;
  },
  methods: {
    ...mapActions("ticketoffice", ["verifyPerson"]),
    ...mapMutations("ticketoffice", ["setVerifyPerson"]),

    activeForm() {
      this.$emit("activeForm");
    },
    restrictCharts($event) {
      if (
        [32, 39, 193, 201, 205].includes($event.charCode) ||
        ($event.charCode >= 65 && $event.charCode <= 90) ||
        ($event.charCode >= 97 && $event.charCode <= 122) ||
        ($event.charCode >= 129 && $event.charCode <= 160) ||
        ($event.charCode >= 208 && $event.charCode <= 250)
      ) {
        return true;
      }

      $event.preventDefault();
    },
    request() {
      this.submitted = true;
      let incomplete_tickets = 0;
      let index = this.structure_ticket.findIndex(
        (e) =>
          (e.validations &&
            Object.values(e.validations).some((o) => o === false)) ||
          e.validate_dni
      );
      if (index >= 0) {
        this.active_index = index;
        return;
      }
      for (let i = 0; i < this.structure_ticket.length; i++) {
        let ident = i;
        let structure_selected = this.structure_ticket[i];
        if (structure_selected.category === "commun") {
          if (!this.ticket_selected_structure.tickets) {
            this.ticket_selected_structure = { tickets: [] };
          }
          this.ticket_selected_structure.tickets.push({
            fk_person: {
              phone_number: structure_selected.person_data.phone_number,
              email: structure_selected.person_data.mail,
              last_name: structure_selected.person_data.last_name,
              first_name: structure_selected.person_data.first_name,
              dni: structure_selected.person_data.dni,
            },
            fk_type: structure_selected.ticket_id,
          });
          if(this.autofilled && i === 0 && this.active_client)
            this.ticket_selected_structure.tickets[i].fk_person.auto_complete = true;
        }
        if (structure_selected.category !== "commun") {
          if (!this.ticket_selected_structure.sponsors) {
            this.ticket_selected_structure = { sponsors: [] };
          }
          this.ticket_selected_structure.sponsors.push({
            enterprise_name: structure_selected.enterprise_name,
            product_name: structure_selected.product_name,
            fk_representative: {
              phone_number: structure_selected.person_data.phone_number,
              email: structure_selected.person_data.mail,
              last_name: structure_selected.person_data.last_name,
              first_name: structure_selected.person_data.first_name,
              dni: structure_selected.person_data.dni,
            },
            fk_event: structure_selected.fk_event,
            fk_type: structure_selected.ticket_id,
            tickets: [],
          });
          if(this.autofilled && i === 0 && this.active_client)
            this.ticket_selected_structure.sponsors[i].fk_representative.auto_complete = true;
          for (let j = 0; j < structure_selected.ticket_structure.length; j++) {
            let data = {
              fk_type: structure_selected.ticket_structure[j].ticket_id,
            };
            if (this.checkValidTicket(structure_selected.ticket_structure[j])) {
              data.fk_person = {
                phone_number:
                  structure_selected.ticket_structure[j].phone_number,
                email: structure_selected.ticket_structure[j].mail,
                last_name: structure_selected.ticket_structure[j].last_name,
                first_name: structure_selected.ticket_structure[j].first_name,
                dni: structure_selected.ticket_structure[j].dni,
              };
            } else {
              incomplete_tickets += 1;
            }
            this.ticket_selected_structure.sponsors[ident].tickets.push(data);
          }
        }
      }
      if (incomplete_tickets) {
        this.$confirm.require({
          message: `Hay ${incomplete_tickets} Entradas de Sponsors sin completar su registro. Confirma que desea dejarlas en blanco? Se le enviará un email con un link donde podrá completar el registro.`,
          header: "Confirmación",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Sí",
          accept: () => {
            //callback to execute when user confirms the action
            parent.postMessage(
              {
                game_service_request: JSON.parse(
                  JSON.stringify(this.ticket_selected_structure)
                ),
              },
              "*"
            );
            // Clean ticket_selected_structure.
            this.ticket_selected_structure = [];
          },
          reject: () => {
            //callback to execute when user rejects the action

            // Clean ticket_selected_structure.
            this.ticket_selected_structure = [];
          },
          onHide: () => {
            //Callback to execute when dialog is hidden
            // Clean ticket_selected_structure.
            this.ticket_selected_structure = [];
          },
        });
      } else {
        parent.postMessage(
          {
            game_service_request: JSON.parse(
              JSON.stringify(this.ticket_selected_structure)
            ),
          },
          "*"
        );
        // Clean ticket_selected_structure.
        this.ticket_selected_structure = [];
      }
    },
    // Sending a message with the autocomplete request active client data to the parent window.
    autocomplete() {
      if (!this.autofilled){
        parent.postMessage(
          {
            autocomplete_request: true,
          },
          "*"
        );
      }
      else {
        this.structure_ticket[0].person_data.phone_number = undefined;
        this.structure_ticket[0].person_data.mail = undefined;
        this.structure_ticket[0].person_data.last_name = undefined;
        this.structure_ticket[0].person_data.first_name = undefined;
        this.structure_ticket[0].person_data.dni = undefined;
        this.structure_ticket[0].validations.phone_number = false;
        this.autofilled = false;
      }
      //this.verifyPersonDni();
    },
    // Checking if the dni exists in the database. If it does, it is setting the validate_dni to true and
    // setting the dni_repeat to true. If it does not, it is setting the validate_dni to false and setting
    // the dni_repeat to false.
    verifyPersonDni() {
      for (let p = 0; p < this.structure_ticket.length; p++) {
        let ticket_actual = this.structure_ticket[p];
        //
        if (
          ticket_actual.person_data.dni != undefined &&
          ticket_actual.person_data.dni != "" &&
          ticket_actual.category === "commun"
        ) {
          this.verifyPerson(ticket_actual.person_data.dni).then(() => {
            ticket_actual.validate_dni = this.person.exists;
            this.dni_repeat = this.person.exists;
          });
        }
        // Checking if the ticket is a sponsor ticket and if it is, it is checking if the dni is not undefined
        // and not empty. If it is not, it is checking if the dni exists in the database. If it does, it is
        // setting the validate_dni to true and setting the dni_repeat to true. If it does not, it is setting
        // the validate_dni to false and setting the dni_repeat to false.
        if (ticket_actual.category !== "commun") {
          for (let s = 0; s < ticket_actual.ticket_structure.length; s++) {
            let ticket_actual_sponsor = ticket_actual.ticket_structure[s];
            if (
              ticket_actual_sponsor.dni != undefined &&
              ticket_actual_sponsor.dni != ""
            ) {
              this.verifyPerson(ticket_actual_sponsor.dni).then(() => {
                ticket_actual_sponsor.validate_dni = this.person.exists;
                this.dni_repeat = this.person.exists;
              });
            }
          }
        }
      }
    },
    checkPersonData(key, ticket, index, event) {
      let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      let value =
        ticket.person_data && !["enterprise_name", "product_name"].includes(key)
          ? ticket.person_data[key]
          : ticket[key];

      if (!ticket.validations) ticket.validations = {phone_number: false};

      if (
        value === undefined ||
        value === null ||
        String(value).trim() === ""
      ) {
        if(key === "dni" && ticket.validate_dni) ticket.validate_dni = false;
        ticket.validations[key] = false;
        return false;
      } else if (key === "phone_number" && event && !event.isValid) {
        ticket.validations[key] = false;
        return false;
      } else if (key === "mail" && !regexEmail.test(value)) {
        ticket.validations[key] = false;
        return false;
      }

      ticket.validations[key] = true;
      return true;
    },
    checkValidTicket(ticket) {
      return (
        !(
          ticket.validations &&
          Object.values(ticket.validations).some((o) => o === false)
        ) && !ticket.validate_dni
      );
    },
  },
  computed: {
    ...mapState("ticketoffice", ["person"]),
  },
};
</script>
<style lang="scss" scoped>
.input-form {
  color: "#404040";
}
.card {
  width: auto;
  border-radius: 0px !important;
}
.continue-button {
  font-family: "Roboto";
  width: 200px;
  height: 50px;
  background: #8f06a4;
  color: #ffff;
  border-style: none;
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
}

.continue-button:hover {
  cursor: pointer;
}
.card :deep(.inner-card) {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px !important;
}
.card :deep(.p-card-title) {
  position: relative;
  padding: 1rem 1rem 0rem 1rem;
  background: #e6e6e6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #8f06a4;
}
.card :deep(.inner-card .p-card-body) {
  padding: 0;
}
.card :deep(.inner-card .p-card-content) {
  padding: 1rem;
}
.auto-fill {
  background-color: transparent;
  border: 1px solid #8f06a4;
  border-radius: 5px;
  color: #8f06a4;
}

.auto-fill:hover {
  background-color: #8f06a4;
  border: 1px solid#e6e6e6;
  color: #e6e6e6;
  cursor: pointer;
}

.p-accordion :deep(.p-accordion-header-link) {
  position: relative;
  padding: 1rem 1rem 1rem 1rem !important;
  background: #e6e6e6 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #8f06a4 !important;
  margin-bottom: 0px;
}
.p-accordion :deep(.p-accordion-tab) {
  margin-bottom: 20px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.ticket-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.info_note > ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 18px;
  color: #938181;
}

:deep(.maz-border) {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.38) !important;
}
:deep(.maz-rounded-lg) {
  border-radius: 0.4rem;
}
:deep(.--is-focused .maz-border) {
  border-color: #8f06a4 !important;
}
.input-phone-label {
  position: absolute;
  top: -10px;
  left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ffff;
  z-index: 4;
  font-size: 12px;
}
:deep(.p-accordion .p-accordion-header-link) {
  padding: 0.6rem !important;
}
@media screen and (max-width: 470px) {
  :deep(.m-phone-number-input__country-flag) {
    display: none;
  }
  :deep(.m-phone-number-input__select) {
    max-width: 6rem;
  }
  :deep(.m-phone-number-input:not(.--no-flags) .m-phone-number-input__select .m-input-wrapper input[id^="Maz"]) {
    padding-left: 0.75rem !important;
  }
  :deep(.m-phone-number-input:not(.--no-flags) .m-phone-number-input__select .m-input-wrapper input) {
    padding-left: 0.75rem !important;
  }
  :deep(.p-accordion .p-accordion-content) {
    padding: 1rem 0.3rem;
  }
  :deep(.ticket-title) {
    font-size: 1.2rem;
  }
  .p-card :deep(.p-card-body) {
    padding: 0.6rem !important;
  }
  .p-card :deep(.p-card-content) {
    padding: 0.1rem !important;
  }
}
</style>

