<template>
  <div class="portada">
    <div
      v-show="!this.showTickes && !this.showSponsors"
    >
      <img
        v-if="$windowSize.width < 770"
        src="/images/game/portada-1-mobile.webp"
        class="background-portada"
      />
      <img
        v-else
        src="/images/game/portada-1.webp"
        class="background-portada"
      />
      <div class="float-m-monagas-innova">
        <img src="/images/game/m-monagas-innova.png" class="background-m-portada" />
      </div>
    </div>
    <div v-if="!this.showTickes && !this.showSponsors" class="flex flex-wrap full-screen">
      <div
        class="col-12 md:col-6 lg:col-6 h-auto md:h-screen md:px-6 container-category"
        style="background-color: rgb(0 198 248 / 61%)"
      >
        <h1 class="text-center text-white">¿Te gustaría participar?</h1>
        <p class="text-white text-center text-2xl mb-6">
          Aprende sobre las innovaciones tecnológicas y construye tu networking
          con fuertes relaciones cara a cara.
        </p>
        <div class="card-button" @click="ticketSelect()">
          <div class="flex align-items-center justify-content-center">
            <span class="product-detail">
              <h3 class="m-0 px-4 md:px-6 py-2">Compra tu Entrada</h3>
            </span>
          </div>
        </div>
      </div>
      <div
        class="col-12 md:col-6 lg:col-6 h-auto md:h-screen md:px-6 container-category"
        style="background-color: rgba(98, 8, 132, 0.61)"
      >
        <h1 class="text-center text-white">¿Te gustaría ser un Sponsor?</h1>
        <p class="text-white text-center text-2xl mb-6">
          Convierte este evento en una oportunidad y gana visibilidad para tu
          negocio.
        </p>
        <div class="card-button" @click="sponsorSelect()">
          <div class="flex align-items-center justify-content-center">
            <span class="product-detail-sponsor">
              <h3 class="m-0 px-4 md:px-6 py-2">Sé Sponsor</h3>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Lottery
    v-if="showTickes || showSponsors"
    @backToSelect="backToSelect"
    :showTickes="showTickes"
    :showSponsors="showSponsors"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";
import Lottery from "../components/lottery/Lottery.vue";

export default {
  emits: ["backToSelect"],
  components: {
    Lottery,
  },
  data() {
    return {
      showTickes: false,
      showSponsors: false,
    };
  },
  mounted() {
    parent.postMessage(
      {
        ready: true,
        keyboard_input: {
          enable: false,
        },
      },
      // this.parent_url
      "*"
    );
  },
  methods: {
    ...mapActions("ticketoffice", [
      "generateTicketsTypes",
      "generateSponsorsTypes",
    ]),
    backToSelect() {
      this.showTickes = false;
      this.showSponsors = false;
    },
    sponsorSelect() {
      this.generateSponsorsTypes().then(() => {
        // A loop that is going through the array of sponsors
        // and adding a new property to each sponsor object.
        for (var i = 0; i < this.tickets.sponsors.length; i++) {
          let ticket_value = this.tickets.sponsors[i];
          ticket_value.tickets_selected = [];
        }
        this.showSponsors = true;
      });
    },
    ticketSelect() {
      this.generateTicketsTypes().then(() => {
        // Looping through the array of tickets and adding a
        // new property to each ticket object.
        for (var i = 0; i < this.tickets.tickets.length; i++) {
          let ticket_value = this.tickets.tickets[i];
          ticket_value.tickets_selected = [];
        }
        this.showTickes = true;
      });
    },
  },
  computed: {
    ...mapState("ticketoffice", ["tickets"]),
  },
};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/space-age");

.card-button {
  width: 100%;
  height: auto;
  border-radius: 6px 6px 6px 6px;
}
.card-button:hover {
  cursor: pointer;
}
.container-category {
  padding-top: 20rem;
  padding-bottom: 20rem;
}
.product-detail {
  padding: 0 20px;
  font-size: 10px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50px;
}
.product-detail:hover {
  padding: 0 19px;
  font-size: 10px;
  color: #fff;
  background-color: #630884;
  border: 1px solid #620884;
  border-radius: 50px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
.product-detail-sponsor {
  padding: 0 20px;
  font-size: 10px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50px;
}
.product-detail-sponsor:hover {
  padding: 0 20px;
  font-size: 10px;
  color: #fff;
  background-color: #00afef;
  border: 1px solid #00afef;
  border-radius: 50px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.full-screen {
  height: 100vh;
}

.float-m-monagas-innova {
  display: block;
  margin: 0 auto;
  width: 28%;
}

.background-m-portada {
  width: 28%;
  position: absolute;
  margin-top: 15em;
}

@media screen and (max-width: 768px) {
  .container-category {
    padding-top: 5.5rem;
    padding-bottom: 6rem;
  }

  .portada h1 {
    font-size: 1.5em;
  }

  .portada p {
    font-size: 1.2em !important;
  }

  .portada h3 {
    font-size: 1.6em;
  }

  .background-m-portada, .float-m-monagas-innova {
    width: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .container-category {
    padding-bottom: 4.5rem;
    padding-top: 4.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
</style>
