<template>
  <div
    class="p-dialog-mask p-component-overlay p-component-overlay-enter"
    style="z-index: 1100"
  >
    <div class="overflow-auto surface-overlay map-modal">
      <div class="flex align-items-center justify-content-end">
        <Button
          icon="pi pi-times"
          class="cancel-buttom"
          @click="$emit('cancel')"
        />
      </div>
      <div class="flex align-items-center justify-content-center">
        <img src="images/Tarima.png" alt="map" class="map" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.map-modal {
  background-color: transparent;
  border-radius: 10px;
  padding: 1rem;
  width: 80%;
  height: 85%;
}
.map {
  width: 84%;
  height: 84%;
  border-radius: 30px;
}
.cancel-buttom {
  background-color: #a707c0;
}

@media screen and (max-width: 1024px) {
  .map {
    transform: rotate(-90deg);
    margin-top: 40%;
    width: 180%;
  }
  .cancel-buttom {
    width: 25px;
    height: 25px;
  }
}
</style>
