<template>
  <div class="portada">
    <div class="text-center py-3 px-6 md:px-3 md:py-3">
      <img
        src="/images/game/logo_monagas_innova.png"
        class="logo_monagas_innova"
      />
    </div>
    <div v-if="!this.active_form">
      <!-- tickets init-->
      <div v-if="this.showTickes">
        <div
          class="flex text-center align-items-center justify-content-center letters"
        >
          <h1 class="text-center">COMPRA TU ENTRADA</h1>
        </div>
        <div
          class="flex flex-wrap align-items-center justify-content-center responsive"
        >
          <div v-for="value in this.tickets.tickets" :key="value.id">
            <div
              class="mt-0"
              v-if="this.tickets.tickets && value.category === 'commun'"
            >
              <div
                class="flex align-items-center justify-content-center mx-2 my-2"
              >
                <div class="container">
                  <div>
                    <div
                      class="card-head"
                      :style="{ 'background-color': value.color }"
                    >
                      <div
                        class="flex justify-items-center justify-content-center"
                      >
                        <span
                          class="product-detail text-center align-items-center justify-content-center"
                        >
                          <h3
                            :class="[
                              'p-3',
                              { 'text-color': value.color === '#ffffff' },
                            ]"
                          >
                            {{ value.name }}
                          </h3>
                        </span>
                      </div>
                      <div
                        class="flex justify-items-center justify-content-center"
                      >
                        <div
                          class="ticketprice flex justify-items-center justify-content-center"
                        >
                          <h2 class="position-price">${{ value.price }}</h2>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-body"
                      :style="{ 'border-bottom-color': value.color }"
                    >
                      <div class="product-properties text-center">
                        <div class="flex inline-flex w-full">
                          <span
                            :class="[
                              'product-price',
                              { 'text-color': value.color === '#ffffff' },
                            ]"
                            :style="{ 'background-color': value.color }"
                          >
                            <span class="mr-2">Disponibles:</span>
                            <span
                              class="text-center"
                              v-if="value.ticket_count >= 0"
                              >{{
                                value.quantity_available - value.ticket_count
                              }}</span
                            >
                            <span v-else>{{ value.quantity_available }}</span>
                          </span>
                        </div>
                        <br />
                        <br />
                        <div class="flex inline-flex">
                          <span class="cantidad mx-2">Cantidad:</span>
                          <div class="flex inline-flex marginInput">
                            <InputText
                              class="inputnumber mx-2"
                              placeholder="0"
                              autocomplete="off"
                              type="text"
                              inputmode="numeric"
                              pattern="[0-9]*"
                              min="0"
                              :max="value.quantity_available"
                              v-model.number="value.ticket_count"
                              @keyup.enter="() => { if (this.button_continue) this.ticketSelected(); }"
                              @keypress="validInput"
                              @keyup="validNumber"
                              enterkeyhint="done"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- tickets end -->

      <!-- sponsors init -->
      <div v-if="this.showSponsors">
        <div class="flex align-items-center justify-content-center letters">
          <h1 class="text-center">COMPRA TU SPONSORÍA</h1>
        </div>
        <div class="flex flex-wrap align-items-center justify-content-center">
          <div v-for="value in this.tickets.sponsors" :key="value.id">
            <div class="py-3">
              <div
                class="flex align-items-center justify-content-center mx-2 my-2"
              >
                <div class="container">
                  <div
                    class="card-head"
                    :style="{ 'background-color': value.color }"
                  >
                    <div
                      class="flex justify-items-center justify-content-center"
                    >
                      <span
                        class="product-detail align-items-center justify-content-center"
                      >
                        <h3
                          :class="[
                            'm-2 text-center',
                            { 'text-color': value.color === '#ffffff' },
                          ]"
                        >
                          {{ value.name }}
                        </h3>
                      </span>
                    </div>
                    <div
                      class="flex justify-items-center justify-content-center"
                    >
                      <div
                        class="ticketprice flex justify-items-center justify-content-center"
                      >
                        <h2 class="position-price">${{ value.price }}</h2>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-body"
                    :style="{ 'border-bottom-color': value.color }"
                  >
                    <div class="product-properties text-center">
                      <div class="flex w-full text-center inline-flex">
                        <span
                          :class="[
                            'product-price',
                            { 'text-color': value.color === '#ffffff' },
                          ]"
                          :style="{ 'background-color': value.color }"
                        >
                          <span class="px-2">Disponibles:</span>
                          <span v-if="value.ticket_count >= 0">{{
                            value.quantity_available - value.ticket_count
                          }}</span>
                          <span v-else>{{ value.quantity_available }}</span>
                        </span>
                      </div>
                      <br />
                      <br />
                      <div
                        class="flex inline-flex w-full justify-content-center align-items-center"
                      >
                        <span class="cantidad font-medium text-center px-3"
                          >Cantidad:</span
                        >
                        <div
                          class="flex inline-flex justify-content-center align-items-center"
                        >
                          <InputText
                            class="inputnumber"
                            placeholder="0"
                            autocomplete="off"
                            type="text"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            min="0"
                            :max="value.quantity_available"
                            v-model.number="value.ticket_count"
                            @keyup.enter="() => { if (this.button_continue) this.ticketSelected(); }"
                            @keypress="validInput"
                            @keyup="validNumber"
                            enterkeyhint="done"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- sponsor end -->
      <div>
        <MapSeat
          class="modal"
          v-if="this.showMapSeat"
          @cancel="showMapSeat = false"
        />
      </div>
      <div class="grid text-center w-full">
        <div class="col-12 md:col-6 lg:col-4">
          <button class="continueButton my-1 md:my-3" @click="backToSelect()">
            ATRÁS
          </button>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
          <button
            class="continueButton my-1 md:my-3"
            @click="showMapSeat = true"
          >
            MAPA DE ASIENTOS
          </button>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
          <button
            v-if="button_continue"
            class="continueButton my-1 md:my-3"
            @click="ticketSelected"
          >
            CONTINUAR
          </button>
        </div>
      </div>
    </div>
  </div>
  <RegisterInfo
    @activeForm="activeForm"
    @backToSelect="backToSelect"
    v-if="this.active_form"
    :tickets_selected="tickets_selected"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import RegisterInfo from "./RegisterInfo.vue";
import MapSeat from "./mapSeat.vue";

export default {
  props: ["showTickes", "showSponsors"],
  emits: ["backToSelect"],
  components: {
    RegisterInfo,
    MapSeat,
  },
  data() {
    return {
      parent_url: process.env.VUE_APP_URL_PARENT,
      active_form: false,
      tickets_selected: null,
      button_continue: false,
      active_client_status: false,
      showMapSeat: false,
    };
  },
  mounted() {
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listening to a message event from the parent window.
    eventer(messageEvent, (e) => {
      let key = e.message ? "message" : "data";
      let data = e[key];

      // Setting the values of the structure_ticket object to the values of the active_client object.
      if (data.active_client_response) {
        this.active_client_status = data.active_client_response;
      }
    });
  },
  methods: {
    ...mapActions("ticketoffice", [
      "generateTicketsTypes",
      "generateSponsorsTypes",
    ]),
    ...mapMutations("ticketoffice", [
      "setGenerateTicketsTypes",
      "setGenerateSponsorsTypes",
    ]),

    ticketSelected() {
      parent.postMessage(
        {
          client_active_request: true,
        },
        "*"
      );
    },
    activeButton(tickets_selected_count) {
      this.tickets_selected = tickets_selected_count;
      if (this.tickets_selected) {
        this.button_continue = false;
        for (var i = 0; i < this.tickets_selected.length; i++) {
          if (this.tickets_selected[i].ticket_count > 0) {
            this.button_continue = true;
            break;
          }
        }
      }
    },
    activeForm() {
      this.active_form = !this.active_form;
      this.active_client_status = false;
    },
    backToSelect() {
      this.$emit("backToSelect");
    },
    validInput(event) {
      if (
        !/\d/.test(event.key) || 
        (Number(String(event.target.value) + String(event.key)) > Number(event.target.max))
      ) 
      {
        return event.preventDefault();
      } 
    },
    validNumber(e) {
      e.target.value = e.target.value.replace(/[^\d]/g, '');
      return false;
    }
  },
  watch: {
    active_client_status() {
      if (this.active_client_status === true) {
        setTimeout(this.activeForm, 0);
      }
    },
    tickets_selected_count() {
      this.activeButton(this.tickets_selected_count);
    },
  },
  computed: {
    ...mapState("ticketoffice", ["tickets"]),

    tickets_selected_count: {
      get() {
        // Filtering the tickets array and returning
        // only the tickets that have a ticket_count.
        if (this.tickets.tickets) {
          return this.tickets.tickets.filter((item) => item.ticket_count);
        }
        // Filtering the sponsors array and returning
        // only the sponsors that have a ticket_count.
        if (this.tickets.sponsors) {
          return this.tickets.sponsors.filter((item) => item.ticket_count);
        }
        return [];
      },
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.cdnfonts.com/css/space-age");
.inputnumber {
  width: 60px;
  height: 30px;
  background: #f9f9f9;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 24px;

  padding-right: 0;
}
.input {
  border-style: solid;
  border-width: 2px;
  border-color: #00c6f8;
  border-left: transparent;
  border-top: transparent;
  border-right: transparent;
  background-color: transparent;
  width: 300px;
  height: 45px;
  color: antiquewhite;
  font-family: "Space Age", sans-serif;
  font-size: large;
}

.back {
  background-color: #a707c0;
  background-image: url(/images/game/back.webp);
  width: 700px;
  height: 280px;
  border-radius: 20px;
}
.letters {
  color: #404040;
  font-family: "Space Age", sans-serif;
  font-size: 1rem;
}
h4 {
  color: antiquewhite;
  font-family: "Space Age", sans-serif;
}
.btn {
  border-style: solid;
  border-width: 1px;
  border-color: antiquewhite;
  background-color: transparent;
  color: antiquewhite;
  font-family: "Space Age", sans-serif;
  border-radius: 20px;
  width: 150px;
  height: 30px;
}
.btn:hover {
  background-color: #00c6f8;
  border-style: none;
}
h3 {
  color: #ffffff;
  font-family: "Space Age", sans-serif;
  font-size: 30px;
}
.sponsor {
  color: antiquewhite;
  font-size: 45px;
  font-family: "sans-serif";
}
button {
  border-style: none;
}
.img {
  margin: 10px auto;
  width: 100%;
  height: 100%;
}
.ticketprice {
  background-color: white;
  /* margin-bottom: 50px; */
  border-style: solid;
  border-color: white;
  border-radius: 65px;
  width: fit-content;
  color: black;
  box-shadow: 0px 4px 4px 2px #00000040;
  width: 130px;
  height: 130px;
  font-weight: 400;
}
.position-price {
  margin-top: 40px;
  font-family: "Roboto";
  font-size: 35px;
  font-weight: 700;
  color: #404040;
}
.active {
  background-color: red;
}
* {
  margin: 0;
}
.card {
  border-radius: 25px;
}
.cantidad {
  font-family: "Roboto";
  font-size: 20px;
}
.product-detail {
  font-size: 12px;
  color: #fff;
}
.container {
  width: 240px;
}
.container-buy-ticket {
  width: 300px;
}
.margin {
  margin-top: 50%;
}
.card-head {
  position: relative;
  height: 190px;
  border-radius: 6px 6px 0 0;
}
.card-button {
  width: 500px;
  height: 190px;
  border-radius: 6px 6px 6px 6px;
  margin-top: 50%;
}
.card-button:hover {
  cursor: pointer;
}

.card-body {
  height: 140px;
  background: #fff;
  border-radius: 0 0 6px 6px;
  border-bottom: 10px solid;
}
.product-price {
  margin-top: 50px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  width: 100%;
}
.pi {
  background-color: transparent;
  font-size: 25px;
}
.pi:hover {
  cursor: pointer;
}
.continueButton {
  width: 280px;
  height: 50px;
  background: #8f06a400;
  border-radius: 50px;
  align-items: center;
  text-align: center;
  color: #8f06a4;
  border: 1px solid #8f06a4;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 2%), 0 0 2px rgb(0 0 0 / 5%),
    0 1px 4px rgb(0 0 0 / 8%) !important;
}
.continueButton:hover {
  cursor: pointer;
  color: #ffffff;
  border: 1px solid #00c6f8;
  background-color: #00c6f8;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}
@media screen and (max-width: 768px) {
  .logo_monagas_innova {
    width: 150px;
  }
}
@media screen and (max-width: 1024px) {
  /* .container-ticket {
    width: 240px;
  }
  .product-price-ticket {
    width: 240px;
  }
  .inputnumber-ticket {
    margin-left: 40px;
  } */
}
.logo_monagas_innova {
  width: 150px;
}
</style>
